import axios from "axios";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  deviceDetect,
  isIOS,
} from "react-device-detect";
import cookie from "react-cookies";
import moment from "moment";

const IPCIDR = require("ip-cidr");
const BigInteger = require("jsbn").BigInteger;

//class UtilFuncs {

export function utf8_to_b64(str) {
  return typeof window !== "undefined"
    ? window.btoa(unescape(encodeURIComponent(str)))
    : "";
}

export function b64_to_utf8(str) {
  return typeof window !== "undefined"
    ? decodeURIComponent(escape(window.atob(str)))
    : "";
}

export function doPostRequest(
  tag,
  url,
  body,
  headers,
  cbkSuccess,
  cbkError,
  cbkFinally
) {
  ////console.log("doPostRequest " + tag);
  axios
    .post(url, body, headers)
    .then((response) => {
      //decode response
      //const response = UtilFuncs.getDecodedStr(process.env.OFFNET_SHAREDKEY, responseenc);
      ////console.log("doPostRequest", tag, responseenc, response);
      ////console.log("doPostRequest", tag, response);
      cbkSuccess(response, this);
    })
    .catch((error) => {
      ////console.log("doPostRequest " + tag + " error", error);
      cbkError(error);
    })
    .finally(function () {
      ////console.log("doPostRequest " + tag + " finally");
      cbkFinally();
    });
}

export function getEncodedComStr(returnUriEncoded) {
  var i;
  var strToEncode = "";
  for (i = 0; i < arguments.length; i++) {
    strToEncode += arguments[i] + "#";
  }
  var result = "";

  var encdata = "/+";
  ////console.log("encode init", encdata, encdata.indexOf("/"), encdata.indexOf("+"));
  while (encdata.indexOf("/") >= 0 || encdata.indexOf("+") >= 0) {
    ////console.log("encode", encdata);
    const fecha = new Date();
    var newstrToEncode = strToEncode + fecha.getTime();
    ////console.log("newstrtoencode", newstrToEncode);
    encdata = getEncodedStr(process.env.COM_SHAREDKEY, newstrToEncode);
    ////console.log("encode result", encdata, encdata.indexOf("/"), encdata.indexOf("+"));
  }
  //encode for url parameter
  result = encodeURIComponent(encdata);

  return result;
}

export function getEncodedComStrNormal() {
  var i;
  var strToEncode = "";
  for (i = 0; i < arguments.length; i++) {
    strToEncode += arguments[i] + "#";
  }
  var result = "";

  const fecha = new Date();
  var newstrToEncode = strToEncode + fecha.getTime();
  ////console.log("newstrtoencode", newstrToEncode);
  result = getEncodedStr(process.env.COM_SHAREDKEY, newstrToEncode);
  ////console.log("encode result", result);

  return result;
}

export function getEncodedStr(key, strToEncode) {
  ////console.log("getencodedstr", key, strToEncode);
  var Cipher = require("aes-ecb");
  //var encdata = "/+";
  //while ((encdata.indexOf("/") >= 0) && (encdata.indexOf("+") >= 0) ) {
  //////console.log("encode", encdata);
  //encdata = Cipher.encrypt(key, strToEncode);
  var encdata = Cipher.encrypt(key, strToEncode);
  //////console.log("encode result", encdata, encdata.indexOf("/"), encdata.indexOf("+"));
  // }
  ////console.log("FINAL encode", encdata);
  return encdata;
}

export function getDecodedStr(key, strToDecode) {
  ////console.log("getdecodedstr", key, strToDecode);
  var Cipher = require("aes-ecb");
  var decdata = Cipher.decrypt(key, strToDecode);
  ////console.log("FINAL decode", decdata);
  return decdata;
}

export function getDecodedJsonObj(strToDecode) {
  ////console.log("getDecodedJsonObj", strToDecode);
  const decodedStr = getDecodedStr(process.env.COM_SHAREDKEY, strToDecode.data);

  ////console.log("decodedStr", decodedStr.substr(0, decodedStr.length - 2));
  var decodedOut = "";
  var error = 0;
  var trimidx = 0;
  if (decodedStr.length > 0) {
    while (error == 0) {
      try {
        decodedOut = {
          data: JSON.parse(decodedStr.substr(0, decodedStr.length - trimidx)),
        };
        error = 1;
      } catch {
        ////console.log("ERROR");
        error = 0;
        trimidx += 1;
        if (trimidx == decodedStr.length) {
          error = 1;
          decodedOut = "";
        }
      }
    }
  }

  ////console.log("getDecodedJsonObj Out", decodedOut);
  return decodedOut;
}

export function markAnalyticsEvent(data, event) {
  const evt = event !== undefined ? event : "click";
  ////console.log("markAnalyticsEvent", evt, data);
  typeof window !== "undefined" &&
    typeof window.gtag !== "undefined" &&
    window.gtag("event", evt, {
      ...data,
    });
}

export function initPopupLib() {
  //if (typeof window !== `undefined`) {
  var $ = require("jquery");
  require("magnific-popup");
  //}
  if (!$(".open-modal").length) {
    return;
  }
  $(".open-modal").magnificPopup({
    type: "inline",
    fixedContentPos: false,
    fixedBgPos: true,
    overflowY: "auto",
    closeBtnInside: true,
    preloader: false,
    midClick: true,
    removalDelay: 300,
    mainClass: "my-mfp-slide-bottom",
  });
}

export function openPopup(idElement) {
  var $ = require("jquery");
  require("magnific-popup");
  $.magnificPopup.open({
    items: {
      src: idElement, // can be a HTML string, jQuery object, or CSS selector
      type: "inline",
    },
  });
}

export function userData(cbkOnFinnish) {
  //DEV
  var urlParams = new URLSearchParams(location.search);
  var hasDevData =
    urlParams.has("mzzodev") &&
    urlParams.get("mzzodev") == "csdfDFGFd98237FADC";
  let hevalueVal = urlParams.has("mzzonum")
    ? urlParams.get("mzzonum")
    : "51945384053";
  let usermsisdnVal = hasDevData == true ? hevalueVal : "";
  let usertypeVal = urlParams.has("mzzotpe") ? urlParams.get("mzzotpe") : "256";
  let modeVal = urlParams.has("mzzomde") ? urlParams.get("mzzomde") : 1; //1: on net, 2: off net, 3: Try & Buy

  const windowGlobal = typeof window !== "undefined" && window;
  if (windowGlobal) {
    //////console.log("from window var......");
    if (window._hevalue !== undefined) {
      //////console.log("has hevalue......");
      hevalueVal = window._hevalue;
    }
    if (window._msisdn !== undefined && window._msisdn != "") {
      //////console.log("has msisdn......");
      let encmsisdn = window._msisdn;
      let enctype = window._type;
      if (process.env.ENCRIPT_ENABLED == true) {
        var Cipher = require("aes-ecb");
        usermsisdnVal = Cipher.decrypt(process.env.HE_SHAREDKEY, encmsisdn);
        usertypeVal = Cipher.decrypt(process.env.HE_SHAREDKEY, enctype);
        //////console.log("auth enc....", usermsisdnVal, usertypeVal);
      } else {
        usermsisdnVal = encmsisdn;
        usertypeVal = enctype;
        //////console.log("unencripted....", usermsisdnVal, usertypeVal);
      }

      modeVal = 1;
    } else {
      //////console.log("no msisdn, testing dev data......");
      modeVal = 2;
    }
  } else {
    if (process.env.ENCRIPT_ENABLED == true) {
      var Cipher = require("aes-ecb");
      usermsisdnVal = Cipher.decrypt(process.env.HE_SHAREDKEY, usermsisdnVal);
      usertypeVal = Cipher.decrypt(process.env.HE_SHAREDKEY, usertypeVal);
    }
  }

  cbkOnFinnish(hevalueVal, usermsisdnVal, usertypeVal, modeVal);
}

export function getUserIP() {
  const windowGlobal = typeof window !== "undefined" && window;
  var userIp = "186.34.0.10"; //DEBUG
  if (windowGlobal) {
    //////console.log("from window var......");
    if (window._ip !== undefined) {
      //////console.log("has hevalue......");
      userIp = window._ip;
    }
  }
  return userIp;
}

export function isIpInRange(userIp, ArrIpRange) {
  var result = false;
  ArrIpRange.map((ipRangeItem, index) => {
    var ipRange = ipRangeItem.node;
    ipRange.field_rango_ip.map((nodeRange, indexNode) => {
      //comparacion
      ////console.log("isIpInRange", userIp, nodeRange);
      if (IPCIDR.isValidAddress(nodeRange)) {
        ////console.log("isIpInRange VALID");
        const cidr = new IPCIDR(nodeRange);
        if (cidr.contains(userIp) == true) {
          //console.log("isIpInRange IP FOUND", userIp, nodeRange);
          result = true;
        }
      }
    });
  });
  return result;
}

//}

export function getApiUrl() {
  return document.location.protocol == "https:"
    ? process.env.SLIM_API_URL_SSL
    : process.env.SLIM_API_URL;
}

export function urlBase64ToUint8Array(base64String) {
  ////console.log("urlBase64ToUint8Array", base64String);
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  ////console.log("base64", base64);
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  ////console.log("outputarray", outputArray);
  return outputArray;
}

export function filterDataByVisibility(data, idTipo, limit, arrPerfilados) {
  ////console.log("filterbyvisibility",data, idTipo, limit, arrPerfilados);
  var arrayData = [];
  data.edges.map((dataItem) => {
    var isOk = true;

    if (isOk) {
      arrayData.push(dataItem);
    }
  });

  ////console.log("filterbyvisibility result",arrayData);
  return arrayData;
}

export function checkVisibility(
  tid,
  arrPerfilados,
  usermsisdn,
  isprepaid,
  ipRanges
) {
  var isOk = false;
  if (tid == 20) {
    //red movil
    //////console.log("filtrar");
    if (usermsisdn != "") {
      //////console.log("44 es perfilado", this.state.usermsisdn, arrPerfilados);
      //return edge.node
      isOk = true;
    }
  }
  if ([45, 49].includes(tid)) {
    //prepago
    //////console.log("filtrar");
    if (isprepaid == true) {
      //////console.log("44 es perfilado", this.state.usermsisdn, arrPerfilados);
      //return edge.node
      isOk = true;
    }
  }
  if ([46, 50].includes(tid)) {
    //postpago
    //////console.log("filtrar");
    if (isprepaid == false) {
      //////console.log("44 es perfilado", this.state.usermsisdn, arrPerfilados);
      //return edge.node
      isOk = true;
    }
  }
  if ([21, 49, 50, 51].includes(tid)) {
    //wifi
    //////console.log("filtrar");
    if (usermsisdn == "") {
      //////console.log("44 es perfilado", this.state.usermsisdn, arrPerfilados);
      //return edge.node
      isOk = true;
    }
  }
  if ([47, 51].includes(tid)) {
    //perfilados
    //////console.log("filtrar");
    if (arrPerfilados.indexOf(usermsisdn) >= 0) {
      //////console.log("44 es perfilado", this.state.usermsisdn, arrPerfilados);
      //return edge.node
      isOk = true;
    }
  }
  if ([76].includes(tid)) {
    //rango de ip
    //console.log("checkVisibility ipranges", isOk);
    if (isIpInRange(getUserIP(), ipRanges) == true) {
      isOk = true;
    }
    //console.log("checkVisibility result", isOk);
  }
  return isOk;
}

export function getEncodedTrafficData(id, url_destino, mzcrmcat) {
  var url_origen =
    typeof window !== "undefined" ? window.location.pathname : "";

  return getEncodedTrafficDataCore(id, url_origen, url_destino, mzcrmcat);
}

export function getEncodedTrafficDataCore(
  id,
  url_origen,
  url_destino,
  mzcrmcat
) {
  ////console.log("getEncodedTrafficData", id, url_destino, mzcrmcat);
  /*
    URL_DESTINO, URL_ORIGEN, ID_BANNER,
    IP, MSISDN, USER_AGENT, TIPO_CONEXION, ID_FAVORITO
    */
  var urlorigen = "";
  var ip = "";
  var msisdn = "";
  var useragent = "";
  var os = "";
  var modelo = "";
  var mercado = "";
  var saldo = "";
  ////console.log("check window")
  if (typeof window !== "undefined") {
    ////console.log("window ok");
    urlorigen = window.location.pathname;
    ip = window._ip != undefined ? window._ip : "";
    msisdn = window._msisdn != undefined ? window._msisdn : "";
    useragent = window._useragent != undefined ? window._useragent : "";
    os = window._os != undefined ? window._os : "";
    modelo = window._modelo != undefined ? window._modelo : "";
    mercado =
      cookie.load("mzcrm[mercado]") != undefined
        ? cookie.load("mzcrm[mercado]")
        : "NN";
    saldo =
      cookie.load("mzcrm[saldo]") != undefined
        ? cookie.load("mzcrm[saldo]")
        : "00";
  }

  var objData = {
    URL_DESTINO: url_destino,
    URL_ORIGEN: urlorigen,
    ID_CONTENIDO: id,
    IP: ip,
    MSISDN: msisdn,
    USER_AGENT: useragent,
    TIPO_CONEXION: "3G",
    MERCADO: mercado,
    SALDO: saldo,
    MZCRMCAT: mzcrmcat,
    OS: os,
    MODELO: modelo,
  };
  ////console.log("URL objData", objData);
  //return utf8_to_b64(JSON.stringify(objData));

  return getEncodedObj(objData);
}

export function getEncodedObj(objData) {
  //array banner nodes
  return utf8_to_b64(JSON.stringify(objData));
}

//export function registerBannerView(id, url_destino, mzcrmcat) {
export function registerBannerView(nodeBanners) {
  //array banner nodes
  ////console.log("registerBannerView", nodeBanners);
  if (process.env.BANNERREGISTERVIEW_ENABLED == "true") {
    var arrBanners = nodeBanners.map((nodeBanner, i) => {
      if (nodeBanner != undefined) {
        return getEncodedTrafficData(
          nodeBanner.node.drupal_internal__nid,
          nodeBanner.node.field_url,
          nodeBanner.node.field_mzcrmcat
        );
      }
    });
    ////console.log("registerBannerView", id, url_destino, mzcrmcat);
    ////console.log("registerBannerView", arrBanners);
    //var dataEnc = getEncodedTrafficData(id, url_destino, mzcrmcat);
    var dataEnc = getEncodedObj(arrBanners);
    ////console.log("dataenc", dataEnc);
    //var urlregisterview = process.env.BANNERREGISTERVIEW_API_URL + "/" +  dataEnc;
    var urlregisterview =
      process.env.BANNERREGISTERVIEW_API_URL + "?data=" + dataEnc;
    ////console.log("url register view", urlregisterview);
    
    /*
    axios
      .get(`${urlregisterview}`)
      .then((response) => {
        //console.log("banner view response", response);
      })
      .catch((error) => {
        //console.log("banner view error", error);
      });
      */

      const getData = async () => {
        try {
          const response = await axios.get(`${urlregisterview}`);
            //console.log("------------------registerview response", response);
        } catch (error){
          //console.log("------------------registerview error", error);
        }
      };

  }
  return;
}

export function registerBannerClick(node, handlebannerclick, event) {
  ////console.log("registerbannerclick", node, handlebannerclick, target);
  if (process.env.BANNERREGISTERCLICK_ENABLED == "true") {
    ////console.log("registerclick enabled", process.env.BANNERREGISTERCLICK_ENABLED);
    //event.target.preventDefault();
    var id = node.drupal_internal__nid;
    var url_destino = node.field_url;
    var mzcrmcat = node.field_mzcrmcat;
    ////console.log("registerBannerClick", id, url_destino, mzcrmcat);
    var dataEnc = getEncodedObj(
      getEncodedTrafficData(id, url_destino, mzcrmcat)
    );
    ////console.log("dataenc", dataEnc);
    var urlregisterclick =
      process.env.BANNERREGISTERCLICK_API_URL + "?data=" + dataEnc;
    ////console.log("url register click", urlregisterclick);
    /*
    axios
      .get(`${urlregisterclick}`)
      .then((response) => {
        ////console.log("response", response);
        ////console.log("calling handlebannerclick");

        handlebannerclick(event);
        ////console.log("ready...");
      })
      .catch((error) => {
        //console.log("error", error);

      });
      */

      const getData = async () => {
        try {
          const response = await axios.get(`${urlregisterclick}`);
            //console.log("------------------registerclick response", response);
        } catch (error){
          //console.log("------------------registerclick error", error);
        }
      };

  } else {
    ////console.log("registerclick disabled", process.env.BANNERREGISTERCLICK_ENABLED);
  }
}

export function registerSiteTraffic(url_origen, url_destino, mzcrmcat) {
  ////console.log("registersitetraffic", url_destino, mzcrmcat);
  if (process.env.SITEREGISTERTRAFFIC_ENABLED == "true") {
    // //console.log("registertraffic enabled", process.env.SITEREGISTERTRAFFIC_ENABLED);

    var id = 0; //no vinculado a contenido
    //var url_destino = node.field_url;
    //var mzcrmcat = node.field_mzcrmcat;
    var dataEnc = getEncodedTrafficDataCore(
      id,
      url_origen,
      url_destino,
      mzcrmcat
    );
    ////console.log("dataenc", dataEnc);
    var urlregistertraffic =
      process.env.SITEREGISTERTRAFFIC_API_URL + "?data=" + dataEnc;

      /*
    axios
      .get(`${urlregistertraffic}`)
      .then((response) => {
        //console.log("------------------registertraffic response", response);
        ////console.log("ready...");
      })
      .catch((error) => {
        //console.log("------------------registertraffic error", error);
      });
      */
      
      const getData = async () => {
        try {
          const response = await axios.get(`${urlregistertraffic}`);
            //console.log("------------------registertraffic response", response);
        } catch (error){
          //console.log("------------------registertraffic error", error);
        }
      };

    } else {
      ////console.log("registertraffic disabled",process.env.SITEREGISTERTRAFFIC_ENABLED);
    }
}

export function filterByMsisdn(msisdn) {
  return filterByMsisdnCbk(msisdn, function (result) {});
}
export function filterByMsisdnCbk(msisdn, cbkFunc) {
  ////console.log("filterByMsisdn", msisdn);
  if (msisdn == "") {
    return false;
  }

  const cookiename = "msisdn_filter_" + msisdn;

  var cookieResult = cookie.load(cookiename);
  if (cookieResult != undefined) {
    ////console.log("filterByMsisdn value by cookie", cookieResult);
    const value = cookieResult == "1";
    if (cbkFunc != undefined) {
      cbkFunc(value);
      return;
    }
  }

  var urlcheckmsisdn = process.env.CHECKMSISDN_API_URL + msisdn;
  ////console.log("url filterByMsisdn", urlcheckmsisdn);

  axios
    .get(`${urlcheckmsisdn}`, {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImQxMTZlYmY0N2Q1NmFjNzEyYjBiN2U5YjAwMDg4M2EyMjUwMjFkYmM1YWM0NzE2YThlMjM4OTdmMmEzZjY3M2E5ODE3YmViYThiODZmNGY1In0.eyJhdWQiOiIxIiwianRpIjoiZDExNmViZjQ3ZDU2YWM3MTJiMGI3ZTliMDAwODgzYTIyNTAyMWRiYzVhYzQ3MTZhOGUyMzg5N2YyYTNmNjczYTk4MTdiZWJhOGI4NmY0ZjUiLCJpYXQiOjE1ODc1MTMwODUsIm5iZiI6MTU4NzUxMzA4NSwiZXhwIjoxNjE5MDQ5MDg1LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.p9pqn4FIh0hJG8rW2Ep-ZzIhCm1j9o10wdzFuE0BTOyyU3b_rL10PlaoG1ck3HINGzRRKwub_az3V0Py99UndjcvLy4Cg7MjXVp_8WDtrFwhD2HOE0Ps4aqxSbQRZ_7pVQaSShqhQotQdEh7EpczqpUhvNz3vUWzCx98xV4-xWr7QltyvI-sj_UvdsXyfZi6q7gfCWFGDFhQlataM3x7N_HuuUWVYmO8veWn497UcmQZuVDB3sAHVQ363yvvBcYfs3cIXY_GZrl2DKACIFw9XegCDtxEmx-qewXvq65AGMTm9MRU2gsQFSCUlg8vZQF-f2oqaOV7ZgzMCkBaUcZ_eafFdCLXifwCqrIITJM0-O7lwTogk_ZHLCOZ5QxvNwNQYhI53htudE-VFjFzjXGMhiqZElbOhcYHfj9R8p5aY78krNXZLE5Zkn1pe3Fqo_FvDS_t21vZVVmPElH_-daQeZC149VYXoOwaIP1DtZtYyV-6llUe4adfkj0DjsVI_lUQIhUKOrtAy4uuKM5rHEhneKQCG8I4tB9C4DKv6M8ogud_wp66Me3ZBsV5rwdoYQhRNdZGsl7aR7vmkaVTkg6iYMS-ASL78p7as856wIpI_8Kh_Hx5jXuKfoGwjfCa0MpxQx47bII4h3Wu4APOq3G6tuNLozMFWKkfpSHvUhf3bY",
        Cookie:
          "XSRF-TOKEN=eyJpdiI6InB3czdaeStiYXR5M1R1VjB1eDRoWkE9PSIsInZhbHVlIjoiT1VXeHVocmRLNVgycEkxQmM0ZWNMZU1idVdadEVvdmFFZkFGUitvaCt4NDZiVVdFdnhPWFFHTTAwRit2bWl6dyIsIm1hYyI6ImY5YWI2ZTc5ZDE2YjRjMDQ3OTA4MDk0MDZlY2Q0NTRhOTE4ZDFkYWY3ZTg2NDM1Y2ZiNjFiOGQ5OWE3ZWUyMjEifQ%3D%3D; laravel_session=eyJpdiI6IlpGUGx0aUFmaVo5MnkzelRXaVpxWFE9PSIsInZhbHVlIjoiRTNyeTlRV2hiXC9odDhTXC94cWdNUncwZWZBTm5nV2NCcm8wdHRjMUY3bTU1T3JIU1BSMkY2RDE5V2kxaWtpTkpxIiwibWFjIjoiMzFhNmQ0YWI3ZDdiYWFjMDRkMzE3MWYwZGIzZGMwMWUwY2IwZGEzNjgyODczNzg0NzM1MDlkNzE2MzY1YzNmYiJ9",
      },
    })
    .then((response) => {
      ////console.log("filterByMsisdn response", response);
      /*
       $resultado = ($dataRes->resultado == true);
       $logMain->debug('**Banner** validacion por endpoint para numero '.$msisdn.' es '.$resultado.'.-');
       $valcookie = ($resultado == true) ? '1' : '0';
       $logMain->debug('**Banner** agregando cookie para numero '.$msisdn.' con valor '.$resultado);
       setcookie('msisdn_filter_'.$msisdn, $valcookie, time()+60*60*24*30, '/');
       */
      var resultado = response.data.resultado == true;
      cookie.save(cookiename, resultado == true ? "1" : "0");
      ////console.log("filterByMsisdn result", resultado, response.data.resultado);

      if (cbkFunc != undefined) {
        ////console.log("filterByMsisdn calling func", cbkFunc);
        cbkFunc(resultado);
      }

      return resultado;
    })
    .catch((error) => {
      ////console.log("filterByMsisdn error", error);
      return false;
    });
}

export function getUserFavorites(msisdn, cbkFavs) {
  //console.log("getuserfavorites", msisdn);
  var urluserfavs = process.env.USERFAVORITES_API_URL + msisdn;
  ////console.log("url user favorites", urluserfavs);
  const defaultFavs = ["685781", "685782", "685783", "685784", "685785"];

  if (msisdn != "") {
    //console.log("from remote");
    axios
      .get(`${urluserfavs}`)
      .then((response) => {
        ////console.log("response", response);
        var remoteFavs = JSON.parse(
          response.data.favoritos.replace(/&quot;/g, "")
        );
        ////console.log("remote favs", remoteFavs);
        if (remoteFavs.length > 0) {
          cbkFavs(remoteFavs);
        } else {
          cbkFavs(defaultFavs);
        }
      })
      .catch((error) => {
        ////console.log("error", error);
        cbkFavs(defaultFavs);
      });
  } else {
    //console.log("from local");
    var selected =
      typeof window !== `undefined`
        ? JSON.parse(
            window.localStorage.getItem("pimclfavs") ||
              "[" + defaultFavs.toString() + "]"
          )
        : defaultFavs;
    cbkFavs(selected);
  }
}

export function setUserFavorites(msisdn, userFavs) {
  ////console.log("setuserfavorites", msisdn, userFavs);
  const userFavsStr = userFavs.join("-");
  var urluserfavs =
    process.env.ADDUSERFAVORITES_API_URL + msisdn + "/" + userFavsStr;
  ////console.log("url user favorites", urluserfavs);

  if (typeof window !== `undefined`) {
    //console.log("storing favs local");
    window.localStorage.setItem("pimclfavs", JSON.stringify(userFavs));
  }
  if (msisdn != "") {
    //console.log("storing favs remote");
    axios
      .get(`${urluserfavs}`)
      .then((response) => {
        ////console.log("response", response);
        //return JSON.parse(response.favoritos);
      })
      .catch((error) => {
        ////console.log("error", error);
        //return [];
      });
  }
}

export function getBannerUrl(node) {
  ////console.log("getBannerUrl", node);

  //var id = node.drupal_id;
  var id = node.drupal_internal__nid;
  var url_destino = node.field_url;
  var mzcrmcat = node.field_mzcrmcat;
  var mercado =
    cookie.load("mzcrm[mercado]") != undefined
      ? cookie.load("mzcrm[mercado]")
      : "NN";
  var saldo =
    cookie.load("mzcrm[saldo]") != undefined
      ? cookie.load("mzcrm[saldo]")
      : "00";
  var origen =
    typeof window !== `undefined`
      ? encodeURIComponent(window.location.pathname)
      : "";
  ////console.log("getBannerUrl registerBannerClick", id, url_destino, mzcrmcat);
  //var dataEnc = encodeURIComponent(getEncodedTrafficData(id, url_destino, mzcrmcat));

  //var dataEnc = encodeURIComponent(getEncodedObj({"ID_CONTENIDO": id}));
  //var dataEnc = id;
  var dataEnc = getEncodedObj([
    getEncodedTrafficData(id, url_destino, mzcrmcat),
  ]);

  ////console.log("getBannerUrl dataenc", dataEnc);
  //var bannerUrl = process.env.BANNERREGISTERCLICK_API_URL + dataEnc;
  var bannerUrl = process.env.BANNERREGISTERCLICK_API_URL + "?data=" + dataEnc;
  //var bannerUrl = process.env.BANNERREGISTERCLICK_API_URL + "?dest=" + encodeURIComponent(url_destino) + "&id=" + id + "&mzcrmcat=" + mzcrmcat + "&mercado=" + mercado + "&saldo=" + saldo + "&origen=" + origen;
  ////console.log("getBannerUrl banner url", bannerUrl);

  return bannerUrl;
}

export function ucfirst(stringVal) {
  if (stringVal != undefined) {
    var firstChar = stringVal.charAt(0).toUpperCase();
    var otherStr = stringVal.slice(1);
    return firstChar + otherStr;
  }
  return stringVal;
}

export function splitArrayToArrayOfArray(array, stride) {
  var tmp = [];
  for (var i = 0; i < array.length; i += stride) {
    tmp.push(array.slice(i, i + stride));
  }
  return tmp;
}

export function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

export function urlImageNotice(node, isSubCategory=false) {
  if(node.field_url_imagen == null){
    //console.log("using default image...");
    return ((isSubCategory == true) ? '../' : '') + "../img/logo-entel.svg";
  }
  return node.field_url_imagen.substring(0, 4) == "http"
    ? node.field_url_imagen
    : node.field_link_origen + node.field_url_imagen;
}

export async function filterBannerItems({data, usermsisdn, isprepaid, isFilteredMsisdn=false, orderByPageView=false, limit=6, categoria, catid, shuffle=false}){
//console.error("filterBannerItems START",this.props);
    ////console.error("banners data", data, this.props);
    //var bannersContent = UtilFuncs.filterDataByVisibility(data.carruselItems);

    var bannersContent = [];

    var isPriority = false;
    //const curhourmin = data.sitePage.context.curhourmin;
    var fecha = new Date();
    var curhourminStr =
      fecha.getHours() +
      (fecha.getMinutes() < 10 ? "0" : "") +
      fecha.getMinutes();
    const curhourmin = parseInt(curhourminStr);
    const curday = fecha.getDay();
    ////console.log("DBG curhourmin curday", curhourmin, curday)
    data.carruselItems.edges.map((dataItem, i) => {
      //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja", dataItem);
      if (
        dataItem.node.relationships.field_tipo_de_contenido[0]
          .drupal_internal__tid == 13
      ) {
        //console.log("banner Principal dataitem", dataItem.node.title, dataItem);
      }
      if (isPriority == false) {
        //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja ispriority false");
        var isOk = false;

        if (
          Array.isArray(dataItem.node.relationships.field_tipo_de_contenido) ==
          false
        ) {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido obj");
          ////console.log("tipo contenido Item",dataItem.node.title,dataItem.node.relationships.field_tipo_de_contenido.drupal_internal__tid,this.props.catid);
          if (
            dataItem.node.relationships.field_tipo_de_contenido
              .drupal_internal__tid == catid
          ) {
            //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido obj OK");
            ////console.log("tipo contenido is OK", dataItem.node.title);
            isOk = true;
          } else {
            //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido obj NOT OK");
            ////console.log("tipo contenido is NOT OK", dataItem.node.title);
          }
        } else {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido array");
          dataItem.node.relationships.field_tipo_de_contenido.map(
            (tipoCont, indexType) => {
              //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido array item", tipoCont, this.props.catid, (tipoCont.drupal_internal__tid == this.props.catid));
              ////console.log("tipo contenido Array",dataItem.node.title,tipoCont.drupal_internal__tid,this.props.catid);

              if (tipoCont.drupal_internal__tid == catid) {
                //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido array OK");
                ////console.log("tipo contenido is OK", dataItem.node.title);
                isOk = true;
              } else {
                //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo contenido array NOT OK");
                ////console.log("tipo contenido is NOT OK", dataItem.node.title);
              }
            }
          );
        }


        //filter horario
        if (isOk == true) {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo categoria ok, horario");
          var isHourOk = false;
          dataItem.node.relationships.field_bloque_horario.map(
            (itemBloque, indexBloque) => {
              itemBloque.field_horarios.map((itemHorario, index) => {
                // ////console.log("DBG idx curday", index, (curday * 2), (curday * 2) + 1);
                //if(index == (curday * 2) || index == ((curday * 2) + 1) ){
                //   ////console.log("DBG solo dia hoy", curday);
                if (
                  itemHorario.starthours <= curhourmin &&
                  curhourmin <= itemHorario.endhours
                ) {
                  if (
                    dataItem.node.relationships.field_tipo_de_contenido[0]
                      .drupal_internal__tid == 13
                  ) {
                    ////console.log("DBG horario coincide",dataItem.node.title,itemHorario.starthours,curhourmin,itemHorario.endhours,itemHorario,index,dataItem.node);
                  }
                  //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo categoria ok, horario OK");
                  isHourOk = true;
                } else {
                  if (
                    dataItem.node.relationships.field_tipo_de_contenido[0]
                      .drupal_internal__tid == 13
                  ) {
                    ////console.log("DBG horario NO coincide",dataItem.node.title,itemHorario.starthours,curhourmin,itemHorario.endhours,itemHorario,index,dataItem.node);
                  }
                }
                //}
              });
            }
          );
          isOk = isHourOk;
        }

        //filter vigencia
        if (isOk == true) {
          var isHourOk = false;
          if (
            moment(moment.utc().format()).isBetween(
              moment
                .parseZone(dataItem.node.field_vigencia.value)
                .utc(true)
                .format(),
              moment
                .parseZone(dataItem.node.field_vigencia.end_value)
                .utc(true)
                .format()
            )
          ) {
            isHourOk = true;
          }
          isOk = isHourOk;
        }

        //filter max clicks y views
        if (isOk == true) {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo horario ok, clicks y views");
          var pageviews =
            dataItem.node.field_pageviews != null
              ? dataItem.node.field_pageviews
              : 0;
          var pageviews_max =
            dataItem.node.field_pageviews_maximo != null
              ? dataItem.node.field_pageviews_maximo
              : 0;
          var clicks =
            dataItem.node.field_clicks != null ? dataItem.node.field_clicks : 0;
          var clicks_max =
            dataItem.node.field_clicks_maximo != null
              ? dataItem.node.field_clicks_maximo
              : 0;
          if (pageviews_max > 0) {
            if (pageviews > pageviews_max) {
              //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo horario ok, clicks y views, excede views");
              // ////console.log("not ok for max views", pageviews, pageviews_max);
              isOk = false;
            } else {
              // ////console.log("OK")
            }
          }
          if (clicks_max > 0) {
            if (clicks > clicks_max) {
              // ////console.log("not ok for max click", clicks, clicks_max);
              //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo horario ok, clicks y views, excede clicks");
              isOk = false;
            } else {
              // ////console.log("OK")
            }
          }
        }

        
        //filter tipo categoria
        //console.log("categoria ", categoria)
        if (isOk == true) {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo horario clicks y views ok, categoria");
          if (categoria != undefined) {
            // ////console.log("banner filtrar por categoria", this.props.categoria, this.props.catid, dataItem.node);
            var isOkCat = false;
            dataItem.node.relationships.field_categoria.map((itemcategoria) => {
              if (
                itemcategoria.drupal_internal__tid ==
                Number.parseInt(categoria)
              ) {
                // ////console.log("banner ok for categoria");
                //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja tipo horario clicks y views ok, categoria OK");
                isOkCat = true;
              } else {
                // ////console.log("banner not ok for categoria");
              }
            });
            isOk = isOkCat;
          }
        }

        if (isOk == true) {
          var isOkCat = false;
          let arrPerfilados = [];
          const perfilados = data.allNodePerfilado.edges.map((perfilado) => {
            arrPerfilados.push(perfilado.node.field_msisdn);
          });
          dataItem.node.relationships.field_condicion_de_visibilidad.map(
            (itemvisibilidad) => {
              isOkCat = checkVisibility(
                itemvisibilidad.drupal_internal__tid,
                arrPerfilados,
                usermsisdn,
                isprepaid,
                data.allNodeRangosIp
              );
              //console.log("banner checkVisibility", dataItem.node.title, itemvisibilidad.drupal_internal__tid, isOkCat);
              /*
							if (itemvisibilidad.drupal_internal__tid == 76) { //rango de ip
								if(UtilFuncs.isIpInRange(UtilFuncs.getUserIP(), data.allNodeRangosIp) == true){
									isOkCat = true;
								}
							}
							*/
            }
          );
          isOk = isOkCat == true ? true : isOk;
          isPriority = isOkCat == true ? true : false;
        }

        if (isOk == true) {
          //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja categoria ok, filtrable msisdn");
          //filtrar por _msisdn
          // ////console.log("DBG banner ok, ver filtrar por msisdn");
          //if(dataItem.node.field_filtrable_por_msisdn == true && this.state.usermsisdn != ""){
          if (dataItem.node.field_filtrable_por_msisdn == true) {
            //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja categoria ok, filtrable msisdn OK");
            // ////console.log("DBG banner filtrable por msisdn", dataItem.node.field_filtrable_por_msisdn, this.state.usermsisdn);
            //if(dataItem.node.field_filtrable_por_msisdn == true ){
            //isOk = UtilFuncs.filterByMsisdn(this.state.usermsisdn);
            //isOk = UtilFuncs.filterByMsisdn('56977797555');
            isOk = isFilteredMsisdn;
            //isOk = true;
            isPriority = isFilteredMsisdn;
            // ////console.log("DBG banner filtrable por msisdn resultado", isOk, isPriority);
          } else {
            // ////console.log("DBG banner NO filtrable por msisdn", dataItem.node.field_filtrable_por_msisdn, this.state.usermsisdn);
          }
        }

        //el OS del movil que entra al portal debe corresponder al OS marcado del banner (drupal)
        if (isOk === true) {
          const even = (element) =>
            (element.name === "Android" && isAndroid) ||
            (element.name === "iOS" && isIOS);
          if (!dataItem.node.relationships.field_sistema_operativo.some(even)) {
            isOk = false;
          }
        }

        if (isOk == true) {
          if (isPriority == true) {
            //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja todo OK, prioritario, unico");
            ////console.log("DBG banner prioritario encontrado, solo se deja este",dataItem);
            //bannersContent = [];
            bannersContent.length = 0;
            bannersContent.push(dataItem);
          } else {
            // ////console.log("DBG banner prioritario NO encontrado, se agrega", dataItem);
            //if(dataItem.node.drupal_internal__nid == 706200) console.error("banner la roja todo OK, agrega a lista");
            bannersContent.push(dataItem);
          }
        }
      }
    });

    //Logica ordernamiento aleatorio
    if(shuffle !== false){
      var n = bannersContent.length;
      var tempArr = [];
      for (var i = 0; i < n - 1; i++) {
        // The following line removes one random element from arr
        // and pushes it onto tempArr
        tempArr.push(
          bannersContent.splice(
            Math.floor(Math.random() * bannersContent.length),
            1
          )[0]
        );
      }
      bannersContent = [...tempArr];
    }

    /* //console.log(bannersContent) */
    //limit filter
    let bannersContentFiltered = bannersContent.slice(0, limit);

    
    //asc desc filter
    //ahora se ordena en la query de graph
    if (orderByPageView !== false) {
      if (orderByPageView === "ASC") {
        bannersContentFiltered.sort(
          (a, b) => a.node.field_pageviews - b.node.field_pageviews
        );
      } else if (orderByPageView === "DESC") {
        bannersContentFiltered.sort(
          (a, b) => b.node.field_pageviews - a.node.field_pageviews
        );
      } else {
        //console.log(`Invalid argument value orderByPageView ${orderByPageView}`);
      }
    }
    /* //console.log(bannersContentFiltered) */
    //mark all banner views
    //const mzcrmcat = UtilFuncs.utf8_to_b64(node.field_mzcrmcat);
    //UtilFuncs.registerBannerView(node.drupal_internal__nid, node.field_url, node.field_mzcrmcat);
    await registerBannerView(bannersContentFiltered);
    //console.error("filterBannerItems END",this.props  ,bannersContentFiltered);

    return bannersContentFiltered;
}

export function getDate(node) {
  let publishDate;
  if (node.field_post_time !== null && node.field_post_date !== null) {
    publishDate = moment(
      node.field_post_date + " " + node.field_post_time,
      "YYYYMMDD h:mm"
    );
  } else {
    if (node.field_post_time !== null && node.field_post_date === null) {
      publishDate = moment(node.field_post_time, "h:mm");
    } else {
      if (node.field_post_time === null && node.field_post_date !== null) {
        publishDate = moment(node.field_post_date);
      } else {
        //created
        //console.log("node created date", node.created);
        publishDate = moment(node.created);

      }
    }
  }
  return publishDate;
}

export function getNodeDate(node) {
  let publishDate;
  if (node.field_post_time !== null && node.field_post_date !== null) {
    publishDate = moment(
      node.field_post_date + " " + node.field_post_time,
      "YYYYMMDD h:mm"
    );
  } else {
    if (node.field_post_time !== null && node.field_post_date === null) {
      publishDate = moment(node.field_post_time, "h:mm");
    } else {
      if (node.field_post_time === null && node.field_post_date !== null) {
        publishDate = moment(node.field_post_date);
      } else {
        //created
        //console.log("node created date", node.created);
        publishDate = moment(node.created);

      }
    }
  }
  return publishDate;
}

export function compareDate(a,b) {
  //let aDate = getDate(a).format();
  //let bDate = getDate(b).format();
  let aDate = a;
  let bDate = b;
  
  if(moment(aDate).isBefore(bDate)){
    return 1;
  }
  if (moment(aDate).isAfter(bDate)) {
    return -1;
  }
  return 0;
  
}

//export default UtilFuncs;
