import "./src/styles/style.scss";
import "magnific-popup/dist/magnific-popup.css";
//import "swiper/swiper.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./src/styles/general/normalize.css";
import "./src/styles/general/font-awesome.css";
import "./src/styles/general.scss";
import "./src/styles/pim.css";
import "./src/styles/estilo.scss";
import "./src/styles/pim.scss";
import * as UtilFuncs from "./src/utils/utils";

import "jquery/dist/jquery.slim";
//import "swiper/js/swiper.min";
global.$ = require("jquery");
import "magnific-popup/dist/jquery.magnific-popup.min";

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export const disableCorePrefetching = () => true;

export const onClientEntry = () => {
  console.log('--------------------onClientEntry------------------');
  
  window.onload = () => {
    console.log('--------------------onClientEntry onload------------------');
  };
};

export const onInitialClientRender = () => {
  window.onload = () => { 
    console.log('--------------------onInitialClientRender after onload MODIF------------------');

    UtilFuncs.userData(
      function (hevalueVal, usermsisdnVal, usertypeVal, modeVal) {
    console.log('--------------------onInitialClientRender userData------------------', usermsisdnVal);
        
        UtilFuncs.markAnalyticsEvent(
      {
        type: "Has Msisdn",
        event_category: "Msisdn",
        event_label: (usermsisdnVal != "") ? "True" : "False",
      },
      "MSISDN"
    );

    UtilFuncs.markAnalyticsEvent(
      {
        type: "Msisdn Value",
        event_category: "Msisdn",
        event_label: usermsisdnVal,
      },
      "MSISDN"
    );

      }.bind(this)
    );
   }
  console.log('--------------------onInitialClientRender------------------');
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  //console.log("onPreRouteUpdate Gatsby started to change location to", location.pathname)
  //console.log("onPreRouteUpdate Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)

  //registro trafico
  registerNewTraffic(location, prevLocation);
};

export const onRouteUpdate = ({ location, prevLocation }, pluginOptions) => {
  //console.log('onRouteUpdate Gatsby new pathname', location.pathname)
  //console.log('onRouteUpdate Gatsby old pathname', prevLocation ? prevLocation.pathname : null)

  markInternalLinkAnalitycs(location, prevLocation, pluginOptions);
};

const markInternalLinkAnalitycs = (location, prevLocation, pluginOptions) => {
  const nolabelTxt = "Sin Label - 6";
  //analytics
  if (prevLocation != null) {
    //no marcar primera entrada

    //var prevLoc = prevLocation ? prevLocation.origin + prevLocation.pathname : null;
    var prevLoc =
      prevLocation != null ? prevLocation.href.replace("#", "") : "";
    var newLoc = location != null ? location.href.replace("#", "") : "";
    if (prevLoc != newLoc) {
      ////console.log("Gatsby marking....");
      var labelAnalytics =
        location.state != null && location.state.field_label_analytics != null
          ? location.state.field_label_analytics
          : prevLocation.state != null &&
            prevLocation.state.field_label_analytics != null
          ? prevLocation.state.field_label_analytics + " (referer)"
          : nolabelTxt; //inverted location & prevlocation
      const logLabel =
        (labelAnalytics == nolabelTxt ? "NOLABEL--------" : "") +
        "Gatsby marking analytics internal link";
      if (labelAnalytics == nolabelTxt) {
        //labelAnalytics = labelAnalytics + JSON.stringify(location) + " ** " +  JSON.stringify(prevLocation) + " ** " + JSON.stringify(pluginOptions);
      }
      /* console.info(
        logLabel,
        location,
        prevLocation,
        newLoc,
        prevLoc,
        pluginOptions
      );
      console.info("Gatsby marking analytics LABEL", labelAnalytics); */
      UtilFuncs.markAnalyticsEvent(
        {
          type: "Enlace Interno",
          src: prevLoc,
          dest: newLoc,
          event_category: "Enlace Interno",
          event_label: labelAnalytics,
        },
        newLoc
      );
    } else {
      /* console.log("marking analytics SAME ROUTE", pluginOptions); */
    }
  }
};

const registerNewTraffic = (location, prevLocation) => {
  ////console.log("registernewtraffic", location, prevLocation);
  var prevLoc = prevLocation ? prevLocation.pathname : "";
  var newLoc = location.pathname;
  var mzcrmcat =
    location.state != null && location.state.field_mzcrmcat != null
      ? location.state.field_mzcrmcat
      : "";

  UtilFuncs.registerSiteTraffic(prevLoc, newLoc, mzcrmcat);
};
